export const White100 = "#F9F9F9";
export const White400 = "#ECECEC";
export const White800 = "#D4D4D4";
export const Black100 = "#818181";
export const Black400 = "#585858";
export const Black800 = "#353535";
export const Peach = "#6497b1";
export const PersianPink = "#005b96";
export const MountbattenPink = "#03396c";
export const Wenge = "#011f4b";
export const AshGrey = "#b3cde0";
