export const buttons = [
    {
      id: 1,
      label: 'Platform',
      path: '/platform',
    },
    {
      id: 2,
      label: 'About Us',
      path: '/about-us',
    },
    {
      id: 3,
      label: 'Dashboard',
      path: '/dashboard',
    },
    {
      id: 4,
      label: 'Login',
      path: '/login',
    },
    {
      id: 5,
      label: 'Logout',
    }
  ]